import React from "react";
import {connect} from "react-redux";
import appConfig from "../appConfig";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import axios from "axios";
import { Trans, withTranslation } from 'react-i18next';
import i18n from "../i18n";
import {DataReporter} from "../DataReporter";
import { MdOutlineNavigateNext } from "react-icons/md";
import { MdOutlineLocalOffer } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { TbListDetails } from "react-icons/tb";
import { FiMail } from "react-icons/fi";
import {isMobile} from "react-device-detect";
import { FaRegFilePdf } from "react-icons/fa6";
import UserButton from "../components/inputs/UserButton";
import TextInput from "../components/inputs/TextInput";
import "../styles/pages/providers.scss";
import {requestSetPersona, requestSetSupportPhoneNrs} from "../store/actions/app";

class Providers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showImageVideo: false,
            selectedBusinessType: null,
            selectedCanDo: null,
            howWorkStep: 1,
            nameValidError: false,
            emailValidError: false,
            emailValidMsg: null,
            submittingForm: false,
            submitSuccess: false,
            requestName: null,
            requestEmail: null,
            requestWebsite: null,
            requestContext: null,
            minInputLength: 6,
            gettingProviderInfo: false,
            providerInfo: null
        };

        this.formRef = React.createRef();
        this.resourcesRef = React.createRef();
        this.yourBenefitsRef = React.createRef();
        this.whatCanDoRef = React.createRef();
        this.howWorksRef = React.createRef();
    }

    componentDidMount() {
        let providerID = new URLSearchParams(this.props.location.search).get("pid");
        if (providerID) this.getProviderInfo(providerID);

        setTimeout(() => this.getSupportPhoneNumbers(), 2500);

        DataReporter.trackMixpanel(this.props,
            "Page view: Providers",
            {
                pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop",
                referralCode: new URLSearchParams(this.props.location.search).get("rc"),
                providerID: providerID,
            });
    }

    getSupportPhoneNumbers() {
        if (!this.state.phoneNrsChecked) {
            try {
                axios
                    .get(appConfig.currentConfig.backendApp.url + "/platform/phonenrs",
                        {
                            headers: {
                                Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                'Content-Type': 'application/json',
                            },
                        })
                    .then(res => {
                        if (res.status === 200) {
                            this.setState({
                                phoneNrsChecked: true
                            }, () => {
                                this.props.dispatchSetPhoneNrs(res.data);
                            });
                        }
                    })
                    .catch(err => {
                        DataReporter.trackSentry(err,
                            { extra: {additionalData: "getSupportPhoneNumbers (1)"} });
                    })

            } catch (err) {
                DataReporter.trackSentry(err,
                    { extra: {additionalData: "getSupportPhoneNumbers (2)"} });
            }
        }
    }

    getProviderInfo(ID) {
        if (!this.state.gettingProviderInfo) {
            this.setState({
                gettingProviderInfo: true
            }, () => {
                axios
                    .get(appConfig.currentConfig.backendApp.url + "/provider/getInfo",
                        {
                            headers: {
                                Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.provider}`,
                                'Content-Type': 'application/json',
                            },
                            params: { ID: ID },
                        })
                    .then(async (res) => {
                        if (res.data.success) {
                            this.setState({
                                gettingProviderInfo: false,
                                providerInfo: res.data.contact
                            }, () => {
                                DataReporter.trackMixpanel(this.props, "Provider: Get Brevo Info Success");
                                DataReporter.identifyMixpanel(this.props, res.data.contact);
                            })
                        } else {
                            this.setState({
                                gettingProviderInfo: false,
                            }, () => {
                                DataReporter.trackMixpanel(this.props, "Provider: Get Brevo Info Failed");
                            })
                        }
                    })
                    .catch(err => {
                        DataReporter.trackMixpanel(this.props, "Error Provider Request");
                        DataReporter.trackSentry(err);
                    })
            });
        }
    }

    formIsValid() {
        let canSave = true;
        this.setState({
            nameValidError: false,
            emailValidMsg: null,
            emailValidError: false
        });

        try {
            if (this.state.requestName === null || this.state.requestName < this.state.minInputLength) {
                DataReporter.trackMixpanel(this.props, "Provider form email invalid");

                this.setState({
                    nameValidError: true
                });

                canSave = false;
            }
        } catch (err) {
            DataReporter.trackSentry(err);
            DataReporter.trackMixpanel(this.props, "Provider form email check failed");
        }

        try {
            if (this.state.requestEmail === null || this.state.requestEmail < this.state.minInputLength
                || !this.state.requestEmail.includes("@")) {

                DataReporter.trackMixpanel(this.props, "Form email invalid");

                this.setState({
                    emailValidError: true,
                    emailValidMsg: "The given email address is not valid."
                });

                canSave = false;
            }
        } catch (err) {
            DataReporter.trackSentry(err);
            DataReporter.trackMixpanel(this.props, "Form email check failed");
        }

        return canSave;
    }

    // Events
    onChangeLanguage(l) {
        DataReporter.trackMixpanel(this.props, "Providers: language change to: " + l);
        setTimeout(
            () => {
                i18n.changeLanguage(l);
            }, 500
        );
    }

    onGetStartedClickHeader() {
        this.setState({
            requestContext: "Header click"
        }, () => {
            DataReporter.trackMixpanel(this.props, "Providers: Header button click");
        });

        const targetYPosition = this.formRef.current.getBoundingClientRect().top;
        window.scrollTo({
            top: window.scrollY + targetYPosition - 80,
            behavior: 'smooth',
        });
    }

    onGetStartedClicWhatCanDo() {
        DataReporter.trackMixpanel(this.props, "Providers: What can do button click");

        const targetYPosition = this.formRef.current.getBoundingClientRect().top;
        window.scrollTo({
            top: window.scrollY + targetYPosition - 80,
            behavior: 'smooth',
        });
    }

    onShowImageVideo() {
        this.setState({
            showImageVideo: true
        }, () => {
            DataReporter.trackMixpanel(this.props, "Providers: Open video");
        });
    }

    onSelectBusinessType(t) {
        this.setState({
            selectedBusinessType: t,
            requestContext: "Why you need Ventrace for business type: " + t
        }, () => {
            DataReporter.trackMixpanel(this.props, "Providers: Business type selected: " + t);

            let targetYPosition = this.yourBenefitsRef.current.getBoundingClientRect().top;
            window.scrollTo({
                top: window.scrollY + targetYPosition - 80,
                behavior: 'smooth',
            });
        });
    }

    onWhyNeedButtonClick() {
        DataReporter.trackMixpanel(this.props, "Providers: Why Need button click");

        const targetYPosition = this.formRef.current.getBoundingClientRect().top;
        window.scrollTo({
            top: window.scrollY + targetYPosition - 80,
            behavior: 'smooth',
        });
    }

    onTestimonialButtontClick() {
        this.setState({
            requestContext: "Testimonials"
        }, () => {
            DataReporter.trackMixpanel(this.props, "Providers: Testimonial button click");
        });

        const targetYPosition = this.formRef.current.getBoundingClientRect().top;
        window.scrollTo({
            top: window.scrollY + targetYPosition - 80,
            behavior: 'smooth',
        });
    }

    onSelectCanDo(s) {
        this.setState({
            selectedCanDo: s,
            requestContext: "What Ventrace can do for your business: " + s
        }, () => {
            DataReporter.trackMixpanel(this.props, "Providers: What can do: " + s);

            let targetYPosition = this.whatCanDoRef.current.getBoundingClientRect().top;
            window.scrollTo({
                top: window.scrollY + targetYPosition - 80,
                behavior: 'smooth',
            });
        });
    }

    onShowNextStep(n=null) {
        let s = this.state.howWorkStep;

        if (n || s < 6) {
            this.setState({
                howWorkStep: n ? n : s+1,
                requestContext: "How Ventrace works, step " + s+1
            }, () => {
                DataReporter.trackMixpanel(this.props, "Providers: How it works: " + s);

                if (isMobile) {
                    let targetYPosition = this.howWorksRef.current.getBoundingClientRect().top;
                    window.scrollTo({
                        top: window.scrollY + targetYPosition - 80,
                        behavior: 'smooth',
                    });
                }
            });
        }
    }

    onSubmitForm() {
        if (!this.state.submittingForm && this.formIsValid()) {
            this.setState({
                submittingForm: true
            }, () => {
                axios
                    .get(appConfig.currentConfig.backendApp.url + "/provider/sendRequest",
                        {
                            headers: {
                                Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.provider}`,
                                'Content-Type': 'application/json',
                            },
                            params: {
                                NAME: this.state.requestName,
                                EMAIL: this.state.requestEmail,
                                WEBSITE: this.state.requestWebsite,
                                CONTEXT: this.state.requestContext
                            },
                        })
                    .then(async (res) => {
                        if (res.data.success) {
                            this.setState({
                                submittingForm: false,
                                submitSuccess: true
                            }, () => {
                                DataReporter.trackMixpanel(this.props, "Send Provider Request Success");
                            })
                        } else {
                            this.setState({
                                submittingForm: false,
                                submitSuccess: false,
                            }, () => {
                                DataReporter.trackMixpanel(this.props, "Send Provider Request Failed");
                            })
                        }
                    })
                    .catch(err => {
                        DataReporter.trackMixpanel(this.props, "Error Provider Request");
                        DataReporter.trackSentry(err);
                    })
            })
        }
    }

    onEmailChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({requestEmail: e.inputValue});
        }
    }

    onEmailPaste(e) {
        this.onEmailChange({inputValue: e.clipboardData.getData("text")});
    }

    onNameChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({requestName: e.inputValue});
        }
    }

    onNamePaste(e) {
        this.onNameChange({inputValue: e.clipboardData.getData("text")});
    }

    onWebsiteChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({requestWebsite: e.inputValue});
        }
    }

    onWebsitePaste(e) {
        this.onWebsiteChange({inputValue: e.clipboardData.getData("text")});
    }

    onDownloadUseCasesEN() {
        DataReporter.trackMixpanel(this.props, "Providers: DL Use-Cases EN", {
            category: "Interaction"}
        );

        setTimeout(() => {
            window.open(process.env.PUBLIC_URL + '/docs/Ventrace Use-Case Retailers_EN.pdf', "_blank");
        }, 300);
    }

    onDownloadUseCasesDE() {
        DataReporter.trackMixpanel(this.props, "Providers: DL Use-Cases DE", {
            category: "Interaction"}
        );

        setTimeout(() => {
            window.open(process.env.PUBLIC_URL + '/docs/Ventrace Anwendungsbeispiele_DE.pdf', "_blank");
        }, 300);
    }

    onDownloadVisualExplainers() {
        DataReporter.trackMixpanel(this.props, "Providers: DL Visual Explainers EN", {
            category: "Interaction"}
        );

        setTimeout(() => {
            window.open(process.env.PUBLIC_URL + '/docs/Ventrace_Visuals-Explainers_EN.pdf', "_blank");
        }, 300);
    }

    onDownloadProviderSlidesEN() {
        DataReporter.trackMixpanel(this.props, "Providers: DL Provider Slides EN", {
            category: "Interaction"}
        );

        setTimeout(() => {
            window.open(process.env.PUBLIC_URL + '/docs/Ventrace_Platform_Providers_EN.pdf', "_blank");
        }, 300);
    }

    onDownloadProviderSlidesDE() {
        DataReporter.trackMixpanel(this.props, "Providers: DL Quick Slides DE");
        setTimeout(() => {
            window.open(process.env.PUBLIC_URL + '/docs/Ventrace_Platform_Providers_DE_Quick.pdf', "_blank");
        }, 300);
    }

    onDownloadPlatformExplainer() {
        DataReporter.trackMixpanel(this.props, "Providers: DL Platform Explainer");

        setTimeout(() => {
            window.open(process.env.PUBLIC_URL + '/docs/Ventrace Platform Explainer v1_EN.pdf', "_blank");
        }, 300);
    }

    onDownloadIntegrationGuide() {
        DataReporter.trackMixpanel(this.props, "Providers: DL Integration Guide");
        setTimeout(() => {
            window.open(process.env.PUBLIC_URL + '/docs/Ventrace Integration Guide v1_EN.pdf', "_blank");
        }, 300);
    }

    onGoToResources(context=null) {
        this.setState({
            requestContext: context
        }, () => {
            DataReporter.trackMixpanel(this.props, "Providers: Go to Resources"
            + context ? " context: " + context : "");
        });

        const targetYPosition = this.resourcesRef.current.getBoundingClientRect().top;
        window.scrollTo({
            top: window.scrollY + targetYPosition - 80,
            behavior: 'smooth',
        });
    }

    onGoSampleCertificate() {
        DataReporter.trackMixpanel(this.props, "Providers: Sample Certificate");
        setTimeout(() => {
            window.open("https://ventrace.com/record?rp=QIXPB0JKWLKL972PPHVC70876", "_blank");
        }, 300);
    }

    onGoToOrderPage() {
        DataReporter.trackMixpanel(this.props, "Providers: Order Page");
        setTimeout(() => {
            window.open("https://ventrace.com/order?t=provider", "_blank");
        }, 300);
    }

    // Renderers
    renderHeadlineSection() {
        let t = this.props.t;

        let yourCompany = this.state.providerInfo
            ? this.state.providerInfo.attributes.COMPANY : t("v_yourBusiness");

        return (
            <div id={"headline-container"}>
                <div id={"text"}>
                    <h2>
                        {t("headline_h1")}
                    </h2>
                    <h3>{t("headline_h2", { company: yourCompany })}</h3>

                    <p id={"descr"}>
                        {t("headline1")}
                    </p>

                    <UserButton
                        forMobile={isMobile}
                        id={"get-started-button"}
                        value={t("headlineButton")}
                        onClick={() => this.onGetStartedClickHeader()}
                    />
                </div>

                <img id={"vr-on-phone-img"}
                     src={process.env.PUBLIC_URL + '/providers/vr-on-phone.jpg'}
                />

            </div>
        );
    }

    renderWhatIsVentrace() {
        let t = this.props.t;

        let ytVideo = (
            <div id={"yt-image-video"}>
                <iframe
                    src="https://www.youtube.com/embed/jkwZ-Cv_5zA"
                    title="YouTube Shorts"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px"
                    }}
                ></iframe>
            </div>
        );

        let imageVideo = this.state.showImageVideo ? ytVideo : (
            <p id={"show-image-video"} onClick={() => this.onShowImageVideo()}>
                {t("whatIsVentraceButton")}
            </p>
        );

        let bgURL = isMobile ? "/providers/wiv_bg_mobile.png" : "/providers/wiv_bg.png";

        return (
            <div id={"what-is-ventrace-container"}>
                <img id={"wiv-bg"}
                     src={bgURL}
                />
                <h2>{t("whatIsVentrace_h")}</h2>
                <p id={"wiv-descr"}>
                    <Trans i18nKey="whatIsVentrace1" components={{ br: <br /> }} />
                </p>
                {imageVideo}
            </div>
        );
    }

    renderWhyNeed() {
        let t = this.props.t;

        let yourBusiness = this.state.providerInfo
            ? this.state.providerInfo.attributes.COMPANY : t("v_yourBusiness");

        let sb = this.state.selectedBusinessType;
        let descr = (
            <p id={"wyu-descr"}>
                <Trans i18nKey="whyNeed_defaultDescr"
                       components={{ br: <br /> }}
                       values={{ yourBusiness: yourBusiness }}
                />
            </p>
        );

        let button = (
            <UserButton
                forMobile={isMobile}
                id={"why-need-button"}
                value={t("whyNeed_button", { yourBusiness: yourBusiness })}
                icon={<IoDocumentTextOutline className={"text-icon"} />}
                onClick={() => this.onWhyNeedButtonClick()}
            />
        );

        if (sb === "jeweller") {
            descr = (
                <div>
                    <p id={"wyu-descr"}>
                        <Trans i18nKey="whyNeed_boutique" components={{ br: <br /> }} />
                    </p>
                    {button}
                </div>
            );
        } else if (sb === "onlineshop") {
            descr = (
                <div>
                    <p id={"wyu-descr"}>
                        <Trans i18nKey="whyNeed_onlineShop" components={{ br: <br /> }} />
                    </p>
                    {button}
                </div>
            );
        } else if (sb === "brand") {
            descr = (
                <div>
                    <p id={"wyu-descr"}>
                        <Trans i18nKey="whyNeed_brand" components={{ br: <br /> }} />
                    </p>

                    {button}
                </div>
            );
        } else if (sb === "repair") {
            descr = (
                <div>
                    <p id={"wyu-descr"}>
                        <Trans i18nKey="whyNeed_repair" components={{ br: <br /> }} />
                    </p>
                    {button}
                </div>
            );
        }

        return (
            <div id={"why-need-container"} ref={this.yourBenefitsRef}>
                <div id={"why-need-content"}>
                    <div id={"explainer-block"}>
                        <h2>{t("whyNeed_h")}</h2>
                        {descr}
                    </div>

                    <div id={"business-types"}>
                        <p id={"label"}>{t("whyNeed_typesLabel")}</p>

                        <div id={"types-list"}>
                            <div className={sb === "jeweller" ? "type sel" : "type"}>
                                <p id={"tl"} onClick={() => this.onSelectBusinessType("jeweller")}>
                                    {t("whyNeed_boutique_l")}
                                </p>
                            </div>

                            <div className={sb === "onlineshop" ? "type sel" : "type"}>
                                <p id={"tl"} onClick={() => this.onSelectBusinessType("onlineshop")}>
                                    {t("whyNeed_onlineShop_l")}
                                </p>
                            </div>

                            <div className={sb === "brand" ? "type sel" : "type"}>
                                <p id={"tl"} onClick={() => this.onSelectBusinessType("brand")}>
                                    {t("whyNeed_brand_l")}
                                </p>
                            </div>

                            <div className={sb === "repair" ? "type sel" : "type"}>
                                <p id={"tl"} onClick={() => this.onSelectBusinessType("repair")}>
                                    {t("whyNeed_repair_l")}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderWatchCollectors() {
        let t = this.props.t;

        let bgURL = isMobile ? "/providers/wc_bg_mobile.png" : "/providers/wc_bg.png";

        return (
            <div id={"watch-collectors"}>
                <img id={"wc-bg"}
                     src={bgURL}
                />

                <h2>{t("watchCollectors_h")}</h2>
                <p id={"wc-descr"}>
                    {t("watchCollectors_d")}
                </p>

                <div id={"headshots"}>

                    <div className={"hs"}>
                        <img className={"hs-pp"}
                             src={process.env.PUBLIC_URL + '/providers/wc-1.jpg'}
                        />
                        <p className={"name"}>Lukas Meinhardt</p>
                        <p className={"quote"}>
                            "{t("watchCollectors_quote1")}"
                        </p>
                    </div>

                    <div className={"hs"}>
                        <img className={"hs-pp"}
                             src={process.env.PUBLIC_URL + '/providers/wc-2.jpg'}
                        />
                        <p className={"name"}>Katarina Weiss</p>
                        <p className={"quote"}>
                            "{t("watchCollectors_quote2")}"
                        </p>
                    </div>

                    <div className={"hs"}>
                        <img className={"hs-pp"}
                             src={process.env.PUBLIC_URL + '/providers/wc-3.jpg'}
                        />
                        <p className={"name"}>Alexander van Dalen</p>
                        <p className={"quote"}>
                            "{t("watchCollectors_quote3")}"
                        </p>
                    </div>

                </div>

                <UserButton
                    forMobile={isMobile}
                    id={"testimonials-button"}
                    value={t("watchCollectors_button")}
                    icon={<MdOutlineLocalOffer className={"text-icon"}/>}
                    onClick={() => this.onTestimonialButtontClick()}
                />
            </div>
        )
    }

    renderWhatVentraceCanDo() {
        let t = this.props.t;
        let canDo = this.state.selectedCanDo;

        let yourBusiness = this.state.providerInfo
            ? this.state.providerInfo.attributes.COMPANY : t("v_yourBusiness");

        let btn = (
            <UserButton
                forMobile={isMobile}
                id={"learn-more-button"}
                value={t("whatCanDo_button")}
                icon={<TbListDetails className={"text-icon"}/> }
                onClick={() => this.onGetStartedClicWhatCanDo()}
            />
        );
        let descr = (
            <div>
                <p id={"expl-h"}>
                    {t("whatCanDo_dflt_h")}
                </p>

                <p id={"expl-descr"}>
                    {t("whatCanDo_dflt_d")}
                </p>
            </div>
        );

        if (canDo === "integration") {
            descr = (
                <div>
                    <p id={"expl-h"}>
                        {t("whatCanDo_integration_h")}
                    </p>

                    <p id={"expl-descr"}>
                        <Trans i18nKey="whatCanDo_integration_d" components={{ br: <br /> }} />
                    </p>
                    {btn}
                </div>
            );
        } else if (canDo === "sales") {
            descr = (
                <div>
                    <p id={"expl-h"}>
                        {t("whatCanDo_sales_h")}
                    </p>

                    <p id={"expl-descr"}>
                        <Trans i18nKey="whatCanDo_sales_d" components={{ br: <br /> }} />
                    </p>
                    {btn}
                </div>
            );
        } else if (canDo === "tracking") {
            descr = (
                <div>
                    <p id={"expl-h"}>
                        {t("whatCanDo_tracking_h")}
                    </p>

                    <p id={"expl-descr"}>
                        <Trans i18nKey="whatCanDo_tracking_d" components={{ br: <br /> }} />
                    </p>
                    {btn}
                </div>
            );
        } else if (canDo === "ownership") {
            descr = (
                <div>
                    <p id={"expl-h"}>
                        {t("whatCanDo_ownership_h")}
                    </p>

                    <p id={"expl-descr"}>
                        <Trans i18nKey="whatCanDo_ownership_d" components={{ br: <br /> }} />
                    </p>
                    {btn}
                </div>
            );
        } else if (canDo === "unique") {
            descr = (
                <div>
                    <p id={"expl-h"}>
                        {t("whatCanDo_unique_h")}
                    </p>
                    <p id={"expl-descr"}>
                        <Trans i18nKey="whatCanDo_unique_d" components={{ br: <br /> }} />
                    </p>
                    {btn}
                </div>
            );
        } else if (canDo === "lossrecovery") {
            descr = (
                <div>
                    <p id={"expl-h"}>
                        {t("whatCanDo_loss_h")}
                    </p>

                    <p id={"expl-descr"}>
                        <Trans i18nKey="whatCanDo_loss_d"
                               components={{ br: <br /> }}
                               values={{ yourBusiness: yourBusiness }}
                        />
                    </p>
                    {btn}
                </div>
            );
        }

        return (
            <div id={"what-ventrace-can-do-container"}>
                <h2>{t("whatCanDo_h", { yourBusiness: yourBusiness })}</h2>

                <div id={"benefits-content"}>
                    <div id={"benefits-picker"}>
                        <p id={"benefits-label"}>
                            {t("whatCanDo_label")}
                        </p>

                        <div id={"benefits-list"}>
                            <div
                                className={canDo === "integration" ? "benefit sel" : "benefit"}
                                onClick={() => this.onSelectCanDo("integration")}
                            >
                                <img className={"benefit-img"}
                                     src={process.env.PUBLIC_URL + '/providers/benefits/seamless.png'}
                                />
                                <p id={"bl"}>{t("whatCanDo_integration_l")}</p>
                            </div>

                            <div
                                className={canDo === "sales" ? "benefit sel" : "benefit"}
                                onClick={() => this.onSelectCanDo("sales")}
                            >
                                <img className={"benefit-img"}
                                     src={process.env.PUBLIC_URL + '/providers/benefits/betterSales.png'}
                                />
                                <p id={"bl"}>{t("whatCanDo_sales_l")}</p>
                            </div>

                            <div
                                className={canDo === "tracking" ? "benefit sel" : "benefit"}
                                onClick={() => this.onSelectCanDo("tracking")}
                            >
                                <img className={"benefit-img"}
                                     src={process.env.PUBLIC_URL + '/providers/benefits/historyBook.png'}
                                />
                                <p id={"bl"}>{t("whatCanDo_tracking_l")}</p>
                            </div>

                            <div
                                className={canDo === "ownership" ? "benefit sel" : "benefit"}
                                onClick={() => this.onSelectCanDo("ownership")}
                            >
                                <img className={"benefit-img"}
                                     src={process.env.PUBLIC_URL + '/providers/benefits/watchOwnership.png'}
                                />
                                <p id={"bl"}>{t("whatCanDo_ownership_l")}</p>
                            </div>

                            <div
                                className={canDo === "unique" ? "benefit sel" : "benefit"}
                                onClick={() => this.onSelectCanDo("unique")}
                            >
                                <img className={"benefit-img"}
                                     src={process.env.PUBLIC_URL + '/providers/benefits/unique.png'}
                                />
                                <p id={"bl"}>{t("whatCanDo_unique_l")}</p>
                            </div>

                            <div
                                className={canDo === "loyalty" ? "benefit sel" : "benefit"}
                                onClick={() => this.onSelectCanDo("lossrecovery")}
                            >
                                <img className={"benefit-img"}
                                     src={process.env.PUBLIC_URL + '/providers/benefits/customerBinding.png'}
                                />
                                <p id={"bl"}>{t("whatCanDo_loyalty_l")}</p>
                            </div>

                        </div>
                    </div>
                    
                    <div id={"benefits-explainer"} ref={this.whatCanDoRef}>
                        {descr}
                    </div>
                </div>
            </div>
        );
    }

    renderHowItWorks() {
        let t = this.props.t;

        let yourBusiness = this.state.providerInfo
            ? this.state.providerInfo.attributes.COMPANY : t("v_yourBusiness");

        let bgURL = isMobile ? "/providers/hw_bg_mobile.png" : "/providers/hw_bg.png";

        let s = this.state.howWorkStep;
        let descr = (
            <div>
                <p id={"step-label"}>
                    {t("howVentraceWorks_s1_h")}
                </p>
                <img className={"how-works-img"}
                     src={process.env.PUBLIC_URL + '/providers/contract.png'}
                />
                <p id={"step-descr"}>
                    <Trans i18nKey="howVentraceWorks_s1_d"
                           components={{ br: <br /> }}
                           values={{ yourBusiness: yourBusiness }}
                    />
                </p>
                <p className={"step-learn-more"} onClick={() => this.onGoToResources("How it works 1")}>
                    {t("howVentraceWorks_s1_link")}
                </p>
            </div>
        );

        if (s === 2) {
            descr = (
                <div>
                    <p id={"step-label"}>
                        {t("howVentraceWorks_s2_h", {yourBusiness: yourBusiness})}
                    </p>
                    <img className={"how-works-img"}
                         src={process.env.PUBLIC_URL + '/providers/dataTransfer.png'}
                    />
                    <p id={"step-descr"}>
                        <Trans i18nKey="howVentraceWorks_s2_d"
                               components={{ br: <br /> }}
                               values={{ yourBusiness: yourBusiness }}
                        />
                    </p>
                    <p className={"step-learn-more"} onClick={() => this.onDownloadPlatformExplainer()}>
                        {t("howVentraceWorks_s2_link")}
                    </p>
                </div>
            );
        } else if (s === 3) {
            descr = (
                <div>
                    <p id={"step-label"}>
                        {t("howVentraceWorks_s3_h")}
                    </p>
                    <img className={"how-works-img"}
                         src={process.env.PUBLIC_URL + '/providers/documentUpload.png'}
                    />
                    <p id={"step-descr"}>
                        <Trans i18nKey="howVentraceWorks_s3_d"
                               components={{ br: <br /> }}
                               values={{ yourBusiness: yourBusiness }}
                        />
                    </p>
                    <p className={"step-learn-more"} onClick={() => this.onDownloadIntegrationGuide()}>
                        {t("howVentraceWorks_s3_link")}
                    </p>
                </div>
            );
        } else if (s === 4) {
            descr = (
                <div>
                    <p id={"step-label"}>
                        {t("howVentraceWorks_s4_h")}
                    </p>
                    <img className={"how-works-img"}
                         src={process.env.PUBLIC_URL + '/providers/certificate.png'}
                    />
                    <p id={"step-descr"}>
                        <Trans i18nKey="howVentraceWorks_s4_d"
                               components={{ br: <br /> }}
                               values={{ yourBusiness: yourBusiness }}
                        />
                    </p>
                    <p className={"step-learn-more"} onClick={() => this.onGoSampleCertificate()}>
                        {t("howVentraceWorks_s4_link")}
                    </p>
                </div>
            );
        } else if (s === 5) {
            descr = (
                <div>
                    <p id={"step-label"}>
                        {t("howVentraceWorks_s5_h")}
                    </p>
                    <img className={"how-works-img"}
                         src={process.env.PUBLIC_URL + '/providers/order.png'}
                    />
                    <p id={"step-descr"}>
                        <Trans i18nKey="howVentraceWorks_s5_d"
                               components={{ br: <br /> }}
                               values={{ yourBusiness: yourBusiness }}
                        />
                    </p>
                    <p className={"step-learn-more"} onClick={() => this.onGoToOrderPage()}>
                        {t("howVentraceWorks_s5_link")}
                    </p>
                </div>
            );
        }

        let nextButton;
        if (s < 5) {
            nextButton = (
                <UserButton
                    forMobile={isMobile}
                    id={"next-step-button"}
                    value={t("howVentraceWorks_button")}
                    icon={<MdOutlineNavigateNext className={"icon"} />}
                    onClick={() => this.onShowNextStep()}
                />
            );
        }

        return (
            <div id={"how-it-works-container"}>
                <img id={"hw-bg"}
                     src={bgURL}
                />

                <h2>{t("howVentraceWorks_h")}</h2>

                <p id={"how-descr"}>
                    {t("howVentraceWorks_d", {yourBusiness: yourBusiness})}
                </p>
                <div id={"steps-container"}>
                    <p className={s === 1 ? "step sel" : (s > 1 ? "step over" : "step")}
                        onClick={() => this.onShowNextStep(1)}
                    >1</p>

                    <p className={s === 2 ? "step sel" : (s > 2 ? "step over" : "step")}
                       onClick={() => this.onShowNextStep(2)}
                    >2</p>

                    <p className={s === 3 ? "step sel" : (s > 3 ? "step over" : "step")}
                       onClick={() => this.onShowNextStep(3)}
                    >3</p>

                    <p className={s === 4 ? "step sel" : (s > 4 ? "step over" : "step")}
                       onClick={() => this.onShowNextStep(4)}
                    >4</p>

                    <p className={s === 5 ? "step sel" : (s > 5 ? "step over" : "step")}
                       onClick={() => this.onShowNextStep(5)}
                    >5</p>
                </div>

                <div id={"step-explain-container"} ref={this.howWorksRef}>
                    {descr}
                    {nextButton}
                </div>
            </div>
        )
    }

    renderContactForm() {
        let t = this.props.t;

        let yourBusiness = this.state.providerInfo
            ? this.state.providerInfo.attributes.COMPANY : t("v_yourBusiness");

        if (this.state.submitSuccess) {
            return (
                <div id={"submit-success-container"}>
                    <img id={"mail-sent-img"}
                         src={process.env.PUBLIC_URL + '/providers/mailSent.png'}
                    />
                    <p id={"mail-sent-text"}>
                        <Trans i18nKey="form_success"
                               components={{ br: <br /> }}
                               values={{ requestName: this.state.requestName }}
                        />
                    </p>
                </div>
            );
        } else {
            return (
                <div id={"contact-form-container"} ref={this.formRef}>
                    <h2>
                        {t("form_h", {yourBusiness: yourBusiness})}
                    </h2>
                    <p id={"contact-descr"}>
                        {t("form_d")}
                    </p>
                    <div id={"contact-form"}>
                        <TextInput
                            inputID={"name-input"}
                            label={t("form_l_yourName")}
                            placeholder={t("form_l_yourName")}
                            onChange={(e) => this.onNameChange(e)}
                            onPaste={(e) => this.onNamePaste(e)}
                            validationError={this.state.nameValidError}
                            validationMsg={"The given name is too short."}
                            maxLen={40}
                            showCharLimit={false}
                        />

                        <TextInput
                            inputID={"email-input"}
                            label={t("form_l_email")}
                            placeholder={`you@company.com`}
                            onChange={(e) => this.onEmailChange(e)}
                            onPaste={(e) => this.onEmailPaste(e)}
                            validationError={this.state.emailValidError}
                            validationMsg={this.state.emailValidMsg}
                            maxLen={40}
                            showCharLimit={false}
                        />

                        <TextInput
                            inputID={"email-input"}
                            label={t("form_l_website")}
                            placeholder={t("form_l_website")}
                            onChange={(e) => this.onWebsiteChange(e)}
                            onPaste={(e) => this.onWebsitePaste(e)}
                            maxLen={40}
                            showCharLimit={false}
                        />

                        <UserButton
                            forMobile={isMobile}
                            id={"submit-form-button"}
                            value={this.state.submittingForm ? t("form_sending_button") : t("form_send_button")}
                            icon={<FiMail className={"text-icon"} />}
                            onClick={() => this.onSubmitForm()}
                            disabled={this.state.submittingForm}
                        />

                    </div>
                </div>
            );
        }
    }

    renderResources() {
        let t = this.props.t;

        return (
            <div id={"resources-container"} ref={this.resourcesRef}>
                <h2>
                    {t("resources_h")}
                </h2>
                <p id={"resources-descr"}>
                    {t("resources_d")}
                </p>

                <div id={"resource-items"}>
                    <div className={"res-item"} onClick={() => this.onDownloadUseCasesEN()}>
                        <FaRegFilePdf className={"pdf-icon"} />
                        <div id={"t"}>
                            <p className={"resource-name"}>
                                Use-Cases for Watch Retailers
                            </p>
                            <p className={"resource-language"}>
                                English
                            </p>
                        </div>
                    </div>

                    <div className={"res-item"} onClick={() => this.onDownloadUseCasesDE()}>
                        <FaRegFilePdf className={"pdf-icon"} />
                        <div id={"t"}>
                            <p className={"resource-name"}>
                                Anwendungsbeispiele für Uhrenhändler
                            </p>
                            <p className={"resource-language"}>
                                Deutsch
                            </p>
                        </div>
                    </div>

                    <div className={"res-item"} onClick={() => this.onDownloadVisualExplainers()}>
                        <FaRegFilePdf className={"pdf-icon"} />
                        <div id={"t"}>
                            <p className={"resource-name"}>
                                Visual Explainers
                            </p>
                            <p className={"resource-language"}>
                                English
                            </p>
                        </div>
                    </div>

                    <div className={"res-item"} onClick={() => this.onDownloadProviderSlidesEN()}>
                        <FaRegFilePdf className={"pdf-icon"} />

                        <div id={"t"}>
                            <p className={"resource-name"}>
                                Ventrace for Businesses Introduction
                            </p>
                            <p className={"resource-language"}>
                                English
                            </p>
                        </div>
                    </div>

                    <div className={"res-item"} onClick={() => this.onDownloadProviderSlidesDE()}>
                        <FaRegFilePdf className={"pdf-icon"} />
                        <div id={"t"}>
                            <p className={"resource-name"}>
                                Ventrace für Unternehmen - Einführung
                            </p>
                            <p className={"resource-language"}>
                                Deutsch
                            </p>
                        </div>
                    </div>

                    <div className={"res-item"} onClick={() => this.onDownloadPlatformExplainer()}>
                        <FaRegFilePdf className={"pdf-icon"} />
                        <div id={"t"}>
                            <p className={"resource-name"}>
                                Ventrace Platform Explainer (v1)
                            </p>
                            <p className={"resource-language"}>
                                English
                            </p>
                        </div>
                    </div>

                    <div className={"res-item"} onClick={() => this.onDownloadIntegrationGuide()}>
                        <FaRegFilePdf className={"pdf-icon"} />
                        <div id={"t"}>
                            <p className={"resource-name"}>
                                Ventrace Integration Guide (v1)
                            </p>
                            <p className={"resource-language"}>
                                English
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    render() {
        let t = this.props.t;
        let mobileSuffix = isMobile ? "-mobile" : "";
        document.title = "Ventrace - For Businesses - Digital Watch Certificates";

        return (
            <div>
                <Navigation />
                <div className={"content-container" + mobileSuffix}
                     id={"provider-container"+ mobileSuffix}>
                    <div id={"lang-selector"}>
                        <p id={"lang-label"}>{t("v_lang")}:</p>
                        <p className={"lang"} onClick={() => this.onChangeLanguage("en")}>English</p>
                        <p className={"lang"} onClick={() => this.onChangeLanguage("de")}>Deutsch</p>
                    </div>
                    <h1>
                        {t("page_h")}
                    </h1>

                    {this.renderHeadlineSection()}
                    {this.renderWhatIsVentrace()}
                    {this.renderWhyNeed()}
                    {this.renderWatchCollectors()}
                    {this.renderWhatVentraceCanDo()}
                    {this.renderHowItWorks()}
                    {this.renderContactForm()}
                    {this.renderResources()}
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchSetPhoneNrs: (phoneNrs) => {
            dispatch(requestSetSupportPhoneNrs(phoneNrs));
        },
        dispatchSetPersona: (persona) => {
            dispatch(requestSetPersona(persona));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('providers')(Providers));