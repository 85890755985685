import React from "react";
import {MdOutlineVerticalAlignTop} from "react-icons/md";
import {AiFillPhone} from "react-icons/ai";
import "../styles/components/footer.scss";
import {connect} from "react-redux";

class Footer extends React.Component {
    // Events
    onBackToTop() {
        window.scrollTo(0, 0);
    }

    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";

        let phoneNr;
        try{
            let phoneNr = this.props.appReducer.supportPhoneNrs.USA;
            if (this.props.appReducer.userRegionInEurope) {
                phoneNr = this.props.appReducer.supportPhoneNrs.Europe;
            }
        }
        catch (exception) {
            phoneNr = "";
        }

        return (
            <div id={"footer" + mobileSuffix}>
                <div id={"content"}>
                    <img
                        id={"logo"}
                        src={process.env.PUBLIC_URL + '/VentraceLogo.png'}
                        alt={"logo"}
                    />
                    <div id={"infos"}>
                        <div className={"infobox"} id={"infobox-company"}>
                            <p className={"name"}>Company</p>

                            <a className={"link"} href={"/providers"}>For Businesses</a>
                            <a className={"link"} href={"/about"}>About</a>
                            <a className={"link"} href={"/terms"}>Terms & Conditions</a>
                            <a className={"link"} href={"/privacypolicy"}>Privacy Policy</a>
                            <a className={"link"} href={"/imprint"}>Imprint</a>
                        </div>

                        <div className={"infobox"} id={"infobox-support"}>
                            <p className={"name"}>Service & Support</p>

                            <a className={"link"} href={"/support"}>Help center</a>
                            <p id={"phone"}>
                                Hotline:
                                <AiFillPhone id={"icon"}/>
                                {phoneNr}
                            </p>
                        </div>
                    </div>

                    <div id={"back-to-top-container"} onClick={() => this.onBackToTop()}>
                        <MdOutlineVerticalAlignTop id={"icon"}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

export default connect(mapStateToProps, null)(Footer);