import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        ns: ["providers"],
        defaultNS: 'providers',
        interpolation: { escapeValue: false },
        resources: {
            en: {
                providers: require("./locales/en/providers.json"),
            },
            de: {
                providers: require("./locales/de/providers.json"),
            }
        }
    });

export default i18n;