import React from "react";
import appConfig from "../appConfig";
import {connect} from "react-redux";
import UserButton from "./inputs/UserButton";
import {GoShieldCheck} from "react-icons/go";
import {BsSpeedometer2} from "react-icons/bs";
import {AiFillPhone} from "react-icons/ai";
import {DataReporter} from "../DataReporter";
import "../styles/components/cta_no_search_res.scss";


class CTANoSearchResult extends React.Component {
    getProductWord(capitalize=false) {
        let productWord = "product";
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            productWord = "watch";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    // Events
    onCreateClick() {
        DataReporter.trackMixpanel(this.props, "CTA: No result", {
            category: "Interaction"
        });

        window.open("/order", "_self");
    }

    renderRecordPresentation() {
        return (
            <div id={"record-presentation"}>
                <div id={"img-container"}>
                    <img id={"record-img"}
                         src={process.env.PUBLIC_URL + '/ventrace_record/VentraceRecord_R02_Frontal.png'}
                         alt={"Ventrace Record"}
                    />
                </div>

                <p id={"name"}>Official Ventrace Record</p>
                <p id={"cat"}>Secure digital object</p>
            </div>
        );
    }

    renderBulletPoints() {
        return (
            <div id={"bp-container"}>
                <div className={"bp-item"}>
                    <div className={"bullet"} />
                    <p className={"bp"}>
                        Get maintenance alerts and verified service records automatically.
                        No more tracking spreadsheets or forgotten service dates.
                    </p>
                </div>

                <div id={"updates-context-container"}>
                    <div id={"update-types-container"}>
                        <p id={"label"}>
                            Your Record can automatically receive verified updates from official Ventrace providers*.
                            These updates could be:
                        </p>
                        <div className={"inline"}>
                            <p className={"ut"}>Inspection reminders</p>
                            <p className={"ut"}>Repair reports</p>
                            <p className={"ut"}>Pre-Owner History</p>
                        </div>
                    </div>
                </div>

                <div className={"bp-item"} id={"bp2"}>
                    <div className={"bullet"} />
                    <p className={"bp"}>
                        Lost or stolen? Activate Recovery Mode to tap into our global network of {this.getProductWord()} professionals.
                        We monitor worldwide activity until your {this.getProductWord()} surfaces.
                    </p>
                </div>

                <div className={"bp-item"}>
                    <div className={"bullet"} />
                    <p className={"bp"}>
                        Build lasting value with a complete, verified history of your {this.getProductWord()}.
                        Every detail documented and authenticated, from manufacture to present day.
                    </p>
                </div>
            </div>
        );
    }

    renderFootnotes() {
        return (
            <div id={"footnotes"}>
                <p><sup>*</sup>Updates provided when available through Ventrace partner network.</p>
                <p><sup>**</sup>Recovery monitoring limited to Ventrace platform activity.</p>
            </div>
        );
    }

    renderPriceBox() {
        let price = "$" + this.props.appReducer.VRprices.standard.toFixed(2);

        if (this.props.appReducer.userRegionInEurope) {
            price = this.props.appReducer.VRprices.standard.toFixed(2) + "€";
        }

        return (
            <div id={"price-box"}>
                <div id={"price"}>
                    {price}
                </div>

                <div id={"guarantees"}>
                    <p className={"guarantee"}>
                        <GoShieldCheck id={"shield-icon"} />
                        30 days full money-back guarantee
                    </p>

                    <p className={"guarantee"}>
                        <BsSpeedometer2 id={"speed-icon"} />
                        Fast order processing
                    </p>
                </div>
            </div>
        );
    }

    renderPaymentMethods() {
        return (
            <div id={"payment-methods"}>
                <img id={"cc"}
                     src={process.env.PUBLIC_URL + '/payment/ccLogos.png'} alt={"cc"}
                />
                <img id={"paypal"}
                     src={process.env.PUBLIC_URL + '/payment/paypalLogo.png'} alt={"paypal"}
                />
            </div>
        );
    }

    renderSupportInfos() {
        let phone = this.props.appReducer.supportPhoneNrs.USA;
        if (this.props.appReducer.userRegionInEurope) {
            phone = this.props.appReducer.supportPhoneNrs.Europe;
        }

        return (
            <div id={"support-infos-container"}>
                <p id={"hotline"}>
                    Any questions? Call <AiFillPhone id={"icon"} /> {phone}
                </p>
            </div>
        );
    }

    renderHeadline() {
        return (
            <p id={"headline"}>A New
                <span className={"hl"}>Standard</span>
                in {this.getProductWord(true)}
                <span className={"hl"}>Ownership</span>
            </p>
        );
    }

    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";

        return(
            <div className={"cta-create-record-search-container" + mobileSuffix}>
                {this.renderHeadline()}

                <div id={"content"}>
                    <div id={"l"}>
                        <div id={"descr"}>
                            Stay ahead of maintenance, protect value, and preserve history.
                            All through one secure digital record.
                        </div>

                        {this.renderBulletPoints()}
                        {this.renderFootnotes()}
                    </div>

                    <div id={"r"}>
                        {this.renderRecordPresentation()}
                        {this.renderPriceBox()}

                        <UserButton
                            forMobile={this.props.forMobile}
                            id={"order-button"}
                            value={"Order now"}
                            onClick={() => this.onCreateClick()}
                        />

                        {this.renderPaymentMethods()}
                        {this.renderSupportInfos()}
                    </div>


                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}


export default connect(mapStateToProps, null)(CTANoSearchResult);