import Mixpanel from "mixpanel-browser";
import appConfig from "./appConfig";
import * as Sentry from "@sentry/react";

export class DataReporter {
    static identifyMixpanel(storageProps, contactData) {
        try {
            if (storageProps.appReducer.cookiesAccepted && appConfig.currentConfig.trackAnalytics) {
                Mixpanel.identify(contactData.email);
                Mixpanel.people.set({
                    email: contactData.email,
                    firstName: contactData.attributes.FIRSTNAME,
                    lastName: contactData.attributes.LASTNAME,
                    company: contactData.attributes.COMPANY
                });
            }
        } catch (err) {
            this.trackSentry(err);
        }
    }

    static trackMixpanel(storageProps, eventName, context={}) {
        try {
            if (storageProps.appReducer.cookiesAccepted && appConfig.currentConfig.trackAnalytics) {
                Mixpanel.track(eventName, context);
            }
        } catch (err) {
            this.trackSentry(err, {"Identify on Mixpanel": eventName});
        }
    }

    static trackSentry(error, context={}) {
        try {
            if (!appConfig.onDev) {
                Sentry.captureException(error, context);
            } else {
                console.log(error);
            }
        } catch (err) {
            console.log("Error tracking Sentry");
            if (appConfig.onDev) console.log(err);
        }
    }
}
